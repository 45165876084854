import { combineReducers } from '@reduxjs/toolkit'
import loginEndpointReducer from './endpoints/login'
import usersOwnEndpointReducer from './endpoints/users/own'
import usersLogoutEndpointReducer from './endpoints/users/logout'
import requestPasswordResetTokenEndpointReducer from './endpoints/change-password/request_reset_token'
import changePasswordEndpointReducer from './endpoints/change-password'
import registerEndpointReducer from './endpoints/register'
import verificationCodeWithEmailEndpointReducer from './endpoints/users/verification-code-with-email'
import usersVerifyEndpointReducer from './endpoints/users/verify'
import organizationInternshipOffersGetEndpointReducer from './endpoints/organization/internship-offers/get'
import organizationInternshipOffersOwnEndpointReducer from './endpoints/organization/internship-offers/own'
import organizationOrganizationalUnitsUsableUsersEndpointReducer from './endpoints/organization/organizational-units/usable_users'
import organizationOrganizationalUnitsOwnEndpointReducer from './endpoints/organization/organizational-units/own'
import organizationInternshipOffersEditEndpointReducer from './endpoints/organization/internship-offers/edit'
import organizationInternshipOffersCreateEndpointReducer from './endpoints/organization/internship-offers/create'
import organizationInternshipsAllEndpointReducer from './endpoints/organization/internships/all'
import permissionsEndpointReducer from './endpoints/permissions'

// Add reducers for API endpoints here
const apiReducer = combineReducers({
  login: loginEndpointReducer,

  /* User and Account Management */
  usersLogout: usersLogoutEndpointReducer,
  usersOwn: usersOwnEndpointReducer,
  requestPasswordResetToken: requestPasswordResetTokenEndpointReducer,
  changePassword: changePasswordEndpointReducer,
  register: registerEndpointReducer,
  verificationCodeWithEmail: verificationCodeWithEmailEndpointReducer,
  usersVerify: usersVerifyEndpointReducer,

  /* Permissions */
  permissions: permissionsEndpointReducer,

  /* Internship Offers */
  organizationInternshipOffersGet:
    organizationInternshipOffersGetEndpointReducer,
  organizationInternshipOffersEdit:
    organizationInternshipOffersEditEndpointReducer,
  organizationInternshipOffersCreate:
    organizationInternshipOffersCreateEndpointReducer,
  organizationInternshipOffersOwn:
    organizationInternshipOffersOwnEndpointReducer,

  /* Usable Users */
  organizationOrganizationalUnitsUsableUsers:
    organizationOrganizationalUnitsUsableUsersEndpointReducer,

  /* Organization */
  organizationOrganizationalUnitsOwn:
    organizationOrganizationalUnitsOwnEndpointReducer,

  /* Internships */
  organizationInternshipsAll: organizationInternshipsAllEndpointReducer,
})

export default apiReducer
