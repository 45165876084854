import React, { forwardRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styles from './FamulenzDateRangePicker.module.scss'
import './ReactDatePicker.scss'
import FamulenzInput from '../Input/FamulenzInput'
import { de } from 'date-fns/locale'
import { BsCalendar } from 'react-icons/bs'

interface IProps {
  onChange: (dates: [Date | null, Date | null]) => void
}

const FamulenzDateRangePicker: React.FC<IProps> = ({ onChange }) => {
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)

  const handleChange = (dates: [Date | null, Date | null]): void => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    onChange(dates)
  }

  /* Custom input for the date range picker */
  interface FamulenzInputDateRangePickerProps {
    value?: string
    onClick?: () => void
  }
  const FamulenzInputDateRangePicker = forwardRef<
    HTMLInputElement,
    FamulenzInputDateRangePickerProps
  >(({ value, onClick }, ref) => (
    <FamulenzInput
      id="famulenz-date-range-picker-input"
      placeholder={'Wann?'}
      onClick={onClick}
      defaultValue={value}
      readOnly
      className={styles.input}
      customIcon={<BsCalendar size={20} className={styles.icon} />}
      onCustomIconClick={onClick}
    />
  ))
  FamulenzInputDateRangePicker.displayName = 'FamulenzInputDateRangePicker'

  return (
    <div className={styles.container}>
      <DatePicker
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        onChange={handleChange}
        customInput={<FamulenzInputDateRangePicker />}
        portalId="root"
        locale={de}
        dateFormat="dd.MM.yyyy"
        className={styles.datePicker}
      />
    </div>
  )
}

export default FamulenzDateRangePicker
