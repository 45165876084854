import FamulenzLogo from 'components/layout/FamulenzLogo/FamulenzLogo'
import React, { useCallback } from 'react'
import { BsInstagram } from 'react-icons/bs'
import styles from './StudentFooter.module.scss'
import FamulenzLink from 'components/control/Link/FamulenzLink'

export default function StudentFooter(): React.JSX.Element {
  const getCurrentYear = useCallback((): number => {
    const date = new Date()
    return date.getFullYear()
  }, [])

  return (
    <footer>
      <div className={styles.container}>
        <div className={styles.containerInner}>
          <div className={styles.upper}>
            <FamulenzLink to="/">
              <FamulenzLogo size="sm" />
            </FamulenzLink>

            <ul className={styles.list}>
              <li>
                <FamulenzLink
                  href="https://www.famulenz.at/ueber-uns"
                  target="_blank"
                  secondary
                >
                  Über uns
                </FamulenzLink>
              </li>
              <li>
                <FamulenzLink
                  className={styles.element}
                  href="https://www.famulenz.at/datenschutz"
                  target="_blank"
                  secondary
                >
                  Datenschutzerklärung
                </FamulenzLink>
              </li>
              <li>
                <FamulenzLink
                  className={styles.element}
                  href="https://www.famulenz.at/impressum"
                  target="_blank"
                  secondary
                >
                  Impressum
                </FamulenzLink>
              </li>
            </ul>
          </div>

          <hr className={styles.divider} />

          <div className={styles.lower}>
            <span className={styles.copyright}>
              © {getCurrentYear()}{' '}
              <FamulenzLink
                href="https://famulenz.at/"
                target="_blank"
                secondary
              >
                Famulenz
              </FamulenzLink>
            </span>

            <div className={styles.social}>
              <FamulenzLink
                href="https://www.instagram.com/famulenz/"
                className={styles.element}
                target="_blank"
                secondary
              >
                <BsInstagram />
                <span className="sr-only">Instagram Seite</span>
              </FamulenzLink>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
