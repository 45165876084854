// TODO: remove es lint ignore
/* eslint-disable @typescript-eslint/no-unused-vars */

import { selectIsUserLoggedIn } from 'app/features/User/UserSlice'
import { useAppSelector } from 'app/hooks'
import FamulenzAlert from 'components/control/Alert/FamulenzAlert'
import FamulenzButton from 'components/control/Button/FamulenzButton'
import FamulenzLink from 'components/control/Link/FamulenzLink'
import StudentContent from 'components/layout/Student/Content/StudentContent'
import React, { useState, useEffect } from 'react'
import { BsGeoAltFill } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { type IInternship, mockInternships } from 'mock/internships'
import FamulenzInternshipList from 'components/layout/Student/InternshipList/FamulenzInternshipList'

export default function StudentInternshipsPage(): React.JSX.Element {
  /* Hooks */
  const navigate = useNavigate()

  // TODO: replace loading the mock data with loading the real data
  const [internships, setInternships] = useState<IInternship[] | null>(null)
  useEffect(() => {
    setInternships(mockInternships)
  }, [])

  if (internships === null) {
    // TODO: show proper error message when internship is not found
    return <></>
  }

  return (
    <StudentContent title="Famulatur Suche">
      <>
        {/* TODO: filter */}
        <FamulenzInternshipList internships={internships} />
      </>
    </StudentContent>
  )
}
