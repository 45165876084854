import { selectIsUserLoggedIn } from 'app/features/User/UserSlice'
import { useAppSelector } from 'app/hooks'
import FamulenzAlert from 'components/control/Alert/FamulenzAlert'
import FamulenzButton from 'components/control/Button/FamulenzButton'
import FamulenzLink from 'components/control/Link/FamulenzLink'
import StudentContent from 'components/layout/Student/Content/StudentContent'
import React, { useState, useEffect } from 'react'
import { BsGeoAltFill } from 'react-icons/bs'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { type IInternship, mockInternships } from 'mock/internships'

export default function StudentInternshipPage(): React.JSX.Element {
  /* Hooks */
  const navigate = useNavigate()

  /* The id of the Internship */
  const { id } = useParams()

  /* Selectors */
  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn)

  // TODO: replace loading the mock data with loading the real data
  const [internship, setInternship] = useState<IInternship | null>(null)
  useEffect(() => {
    setInternship(null)
    if (id !== undefined) {
      mockInternships.forEach(internship => {
        if (internship.id === parseInt(id)) {
          setInternship(internship)
        }
      })
    }
  }, [id])

  if (internship === null) {
    // TODO: show proper error message when internship is not found
    return <></>
  }

  return (
    <StudentContent title="Detailinformationen zur Famulatur">
      <>
        {/* Internship title and location */}
        {/* TODO: move classes to scss module */}
        <div className="space-y-8">
          <div className="space-y-4">
            <h2 className="text-2xl font-semibold" data-aos="fade-up">
              {internship.department}
            </h2>
            <p
              className="text-gray-600 inline-flex space-x-2"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <BsGeoAltFill size="20" className="text-green-400" />
              <FamulenzLink
                to={internship.locationUrl}
                target="_blank"
                secondary
              >
                {internship.location}
              </FamulenzLink>
            </p>
          </div>

          {/* Internship description */}
          <div className="space-y-8">
            <div
              className="grid grid-cols-1 md:grid-cols-2 gap-8"
              data-aos="fade-up"
            >
              <div className="space-y-4">
                <p>{internship.description}</p>
              </div>
              <div className="flex items-center justify-center h-full">
                <img
                  src={internship.imageSrc}
                  alt="Foto der Abteilung"
                  className="rounded-lg h-full object-cover"
                  data-aos="flip-right"
                />
              </div>
            </div>
          </div>

          {/* Internship tasks */}
          <div className="space-y-8" data-aos="fade-up">
            <div className="space-y-4">
              <h3 className="text-xl font-semibold">
                Deine Aufgaben & Lernziele
              </h3>
              <ul className="list-disc list-inside space-y-2 marker:text-green-400">
                {internship.tasks.map(
                  (
                    task:
                      | string
                      | number
                      | boolean
                      | React.ReactElement<
                          any,
                          string | React.JSXElementConstructor<any>
                        >
                      | Iterable<React.ReactNode>
                      | React.ReactPortal
                      | null
                      | undefined,
                    index: React.Key | null | undefined,
                  ) => (
                    <li key={index}>{task}</li>
                  ),
                )}
              </ul>
            </div>
          </div>

          {/* Working hours and conditions */}
          <div className="space-y-8" data-aos="fade-up">
            <div className="space-y-4">
              <h3 className="text-xl font-semibold">
                Arbeitszeiten & Rahmenbedingungen
              </h3>

              {/* Login to see more */}
              {!isUserLoggedIn && (
                <FamulenzAlert type="warning" title="Login erforderlich">
                  Um weitere Informationen zu dieser Stelle zu erhalten,{' '}
                  <FamulenzLink to="/login" secondary>
                    logge dich bitte ein
                  </FamulenzLink>
                  . Falls du noch kein Konto hast, kannst du dich{' '}
                  <FamulenzLink to="/register" secondary>
                    kostenlos registrieren
                  </FamulenzLink>
                </FamulenzAlert>
              )}

              {isUserLoggedIn && (
                <ul className="list-disc list-inside space-y-2 marker:text-green-400">
                  <li>
                    <span className="underline">Arbeitszeiten:</span>{' '}
                    {internship.workingHours}
                  </li>
                  <li>
                    <span className="underline">Einrichtungen:</span>{' '}
                    {internship.facilities}
                  </li>
                  <li>
                    <span className="underline">Verpflegung:</span>{' '}
                    {internship.catering}
                  </li>
                  <li>
                    <span className="underline">Unterkunft:</span>{' '}
                    {internship.accommodation}
                  </li>
                </ul>
              )}
            </div>
          </div>

          {/* Contact person and application */}
          {isUserLoggedIn && (
            <div className="space-y-8" data-aos="fade-up">
              <div className="space-y-4">
                <h3 className="text-xl font-semibold">
                  Informationen zur Bewerbung
                </h3>

                <p className="font-semibold">Benötigte Unterlagen:</p>
                <ul className="list-disc list-inside space-y-2 marker:text-green-400">
                  {internship.application.requiredDocuments.map(
                    (document, index) => (
                      <li key={index}>{document}</li>
                    ),
                  )}
                </ul>

                <p className="font-semibold">Dauer:</p>
                <ul className="list-disc list-inside space-y-2 marker:text-green-400">
                  <li>Mindestens {internship.application.minDuration}</li>
                  <li>Maximal {internship.application.minDuration}</li>
                </ul>

                <p>
                  Bewerbungen können über den nachfolgenden Button eingereicht
                  werden.
                </p>
              </div>

              <div className="space-y-4">
                <h3 className="text-xl font-semibold">Betreuer</h3>

                <p>{internship.contactPerson.name}</p>
                <FamulenzLink to={`mailto:${internship.contactPerson.email}`}>
                  {internship.contactPerson.email}
                </FamulenzLink>
              </div>
            </div>
          )}

          {/* Apply now button */}
          {isUserLoggedIn && (
            <div className="flex justify-center" data-aos="fade-up">
              <FamulenzButton
                onClick={() => {
                  toast.warning(
                    'Eine Bewerbung für diese Stelle ist derzeit nicht möglich.',
                  )
                }}
              >
                Jetzt bewerben
              </FamulenzButton>
            </div>
          )}

          {/* Login to apply */}
          {!isUserLoggedIn && (
            <div className="flex justify-center" data-aos="fade-up">
              <FamulenzButton
                onClick={() => {
                  navigate('/login')
                }}
              >
                Einloggen um zu bewerben
              </FamulenzButton>
            </div>
          )}
        </div>
      </>
    </StudentContent>
  )
}
