export const mockInternships = [
  {
    id: 4,
    shortTitle:
      'Famulatur Plastische, Ästhetische und Rekonstruktive Chirurgie',
    department:
      'Klinische Abteilung für Plastische, Ästhetische und Rekonstruktive Chirurgie',
    location: 'LKH-Universitätsklinikum Graz',
    locationUrl: 'https://maps.app.goo.gl/6tXVxesrbRNVjYAK8',
    imageSrc:
      'https://www.uniklinikumgraz.at/fileadmin/_processed_/c/5/csm_Plastische_Banner_f14fc688e1.jpg',
    description: `Diese Abteilung bietet dir die Möglichkeit, die gesamte Bandbreite der Plastischen, Ästhetischen und Rekonstruktiven Chirurgie kennenzulernen. Du erhältst Einblicke in die rekonstruktive Chirurgie (z. B. Brustwiederaufbau, Nervenrekonstruktion), Handchirurgie (inkl. Replantationen und degenerative Erkrankungen), Verbrennungschirurgie sowie ästhetische Eingriffe (z. B. Facelifts, Brust- und Nasenkorrekturen). Durch die interdisziplinäre Zusammenarbeit und die forschungsorientierte Ausrichtung der Abteilung kannst du dein Wissen gezielt erweitern.`,
    tasks: [
      'Mitarbeit in der Ambulanz und auf Station - Einblicke in die klinische Patientenbetreuung',
      'Assistenz im OP - Unterstützung bei plastisch-chirurgischen Eingriffen',
      'Erlernen von Naht- und Verbandstechniken - praktische Fertigkeiten verbessern',
      'Teilnahme an Morgen- & interdisziplinären Besprechungen - Abläufe und Fallanalysen kennenlernen',
      'Vertiefung anatomischer und chirurgischer Kenntnisse - Theorie und Praxis verknüpfen',
    ],
    contactPerson: {
      name: 'Assoz.-Prof. Priv.-Doz. Dr. David Benjamin Lumenta',
      email: 'david.lumenta@medunigraz.at',
    },
    workingHours: 'Montag - Freitag, Beginn mit Morgenbesprechung um 07:20 Uhr',
    facilities: 'OP, Tagesklinik, Spezialambulanzen, Station',
    catering: 'Kostenloser Zugang zur Krankenhausmensa',
    accommodation: 'Nicht vorhanden',
    application: {
      requiredDocuments: [
        'Lebenslauf',
        'Immatrikulationsbescheinigung',
        'Impfstatus',
      ],
      minDuration: '14 Tage',
      maxDuration: '28 Tage',
    },
  },
  {
    id: 1,
    shortTitle: 'Famulatur Herzchirurgie',
    department: 'Klinische Abteilung für Herzchirurgie',
    location: 'LKH-Universitätsklinikum Graz',
    locationUrl: 'https://maps.app.goo.gl/6tXVxesrbRNVjYAK8',
    imageSrc:
      'https://www.uniklinikumgraz.at/fileadmin/_processed_/1/f/csm_Herzchirurgie_Banner_b50e68bcae.jpg',
    description: `Die Klinische Abteilung für Herzchirurgie bietet dir die Möglichkeit, das gesamte Spektrum der modernen Herzchirurgie kennenzulernen. Die Abteilung ist spezialisiert auf angeborene und erworbene Herzerkrankungen, darunter Aorten- und Klappenchirurgie, Bypass-Operationen, die Implantation von Unterstützungssystemen (ECMO, VAD) sowie die Kinderherzchirurgie.`,
    tasks: [
      'Mitarbeit in der Ambulanz und auf Station - Einblicke in die Betreuung von herzchirurgischen Patient*innen',
      'Assistenz im OP - Unterstützung bei herzchirurgischen Eingriffen',
      'Erlernen von Naht- und Gefäßtechniken - praktische Fertigkeiten verbessern',
      'Teilnahme an Morgen- & interdisziplinären Besprechungen - Abläufe und Fallanalysen kennenlernen',
      'Vertiefung anatomischer und physiologischer Kenntnisse in der Herzchirurgie',
    ],
    contactPerson: {
      name: 'Univ.-Prof. Dr. Günther Laufer',
      email: 'günther.laufer@medunigraz.at',
    },
    workingHours: 'Montag - Freitag, Beginn mit Morgenbesprechung um 08:00 Uhr',
    facilities: 'OP, Intensivstation, Spezialambulanzen, Station',
    catering: 'Kostenloser Zugang zur Krankenhausmensa',
    accommodation: 'Nicht vorhanden',
    application: {
      requiredDocuments: [
        'Lebenslauf',
        'Immatrikulationsbescheinigung',
        'Impfstatus',
      ],
      minDuration: '14 Tage',
      maxDuration: '28 Tage',
    },
  },
  {
    id: 2,
    shortTitle: 'Famulatur Nephrologie',
    department: 'Klinische Abteilung für Nephrologie',
    location: 'LKH-Universitätsklinikum Graz',
    locationUrl: 'https://maps.app.goo.gl/6tXVxesrbRNVjYAK8',
    imageSrc:
      'https://nephrologie.medunigraz.at/frontend/_processed_/3/2/csm_nephrologie_80a0b3d83b.png',
    description: `Die Klinische Abteilung für Nephrologie ist ein akademisches Zentrum, das sich auf die Versorgung akuter und chronischer Nierenerkrankungen spezialisiert hat. Unsere Abteilung legt großen Wert auf evidenzbasierte Medizin, interdisziplinäre Zusammenarbeit und innovative Forschung.`,
    tasks: [
      'Mitarbeit in der Ambulanz und auf Station - Betreuung von Patient*innen mit akuten und chronischen Nierenerkrankungen',
      'Begleitung von Dialysepatient*innen - Einblicke in Hämodialyse und Peritonealdialyse',
      'Kennenlernen extrakorporaler Spezialbehandlungen - z. B. Plasmapherese, Immunadsorption',
      'Teilnahme an interdisziplinären Fallbesprechungen - Austausch mit Transplantationsmedizin und Rheumatologie',
      'Vertiefung nephrologischer Krankheitsbilder und Therapieansätze',
    ],
    contactPerson: {
      name: 'Univ.-Prof. Dr. Alexander Rosenkranz',
      email: 'alexander.rosenkranz@medunigraz.at',
    },
    workingHours: 'Montag - Freitag, Beginn mit Morgenbesprechung um 07:30 Uhr',
    facilities: 'Ambulanz, Dialysezentrum, Station',
    catering: 'Kostenloser Zugang zur Krankenhausmensa',
    accommodation: 'Nicht vorhanden',
    application: {
      requiredDocuments: [
        'Lebenslauf',
        'Immatrikulationsbescheinigung',
        'Impfstatus',
      ],
      minDuration: '14 Tage',
      maxDuration: '28 Tage',
    },
  },
  {
    id: 3,
    shortTitle: 'Famulatur Rheumatologie und Immunologie',
    department: 'Klinische Abteilung für Rheumatologie und Immunologie',
    location: 'LKH-Universitätsklinikum Graz',
    locationUrl: 'https://maps.app.goo.gl/6tXVxesrbRNVjYAK8',
    imageSrc:
      'https://rheumatologie.medunigraz.at/frontend/_processed_/b/a/csm_rheumatologie_5e0969f3fe.png',
    description: `Diese Abteilung ist spezialisiert auf die Diagnostik und Therapie entzündlich-rheumatischer und immunologischer Erkrankungen. Der Fokus liegt auf Autoimmunerkrankungen, systemischen Vaskulitiden, Kollagenosen und entzündlichen Gelenkerkrankungen.`,
    tasks: [
      'Mitarbeit in der Ambulanz und auf Station - Betreuung von Patient*innen mit rheumatischen und immunologischen Erkrankungen',
      'Erlernen von Gelenksonographie und Kapillarmikroskopie',
      'Teilnahme an interdisziplinären Besprechungen',
      'Vertiefung immunologischer Krankheitsbilder und Therapieansätze',
    ],
    contactPerson: {
      name: 'Univ.-Prof. Dr. Jens Thiel',
      email: 'jens.thiel@medunigraz.at',
    },
    workingHours: 'Montag - Freitag, Beginn mit Morgenbesprechung um 08:00 Uhr',
    facilities: 'OP, Intensivstation, Spezialambulanzen, Station',
    catering: 'Kostenloser Zugang zur Krankenhausmensa',
    accommodation: 'Nicht vorhanden',
    application: {
      requiredDocuments: [
        'Lebenslauf',
        'Immatrikulationsbescheinigung',
        'Impfstatus',
      ],
      minDuration: '14 Tage',
      maxDuration: '28 Tage',
    },
  },
  {
    id: 5,
    shortTitle: 'Famulatur Gastroenterologie und Hepatologie',
    department: 'Klinische Abteilung für Gastroenterologie und Hepatologie',
    location: 'LKH-Universitätsklinikum Graz',
    locationUrl: 'https://maps.app.goo.gl/6tXVxesrbRNVjYAK8',
    imageSrc:
      'https://gastroenterologie.medunigraz.at/frontend/_processed_/3/2/csm_gastroenterologie_2bb1bb6277.png',
    description: `Diese Abteilung ist auf die Diagnostik und Therapie von Erkrankungen des Magen-Darm-Trakts, der Leber, der Gallenwege und des Pankreas spezialisiert.`,
    tasks: [
      'Mitarbeit in der Ambulanz und auf Station - Betreuung von Patient*innen mit gastroenterologischen und hepatologischen Erkrankungen',
      'Lernen von endoskopischen Techniken und sonographischer Diagnostik',
      'Teilnahme an interdisziplinären Besprechungen',
      'Vertiefung gastroenterologischer und hepatologischer Krankheitsbilder',
    ],
    contactPerson: {
      name: 'Univ.-Prof. Dr. Peter Fickert',
      email: 'peter.fickert@medunigraz.at',
    },
    workingHours: 'Montag - Freitag, Beginn mit Morgenbesprechung um 08:00 Uhr',
    facilities: 'OP, Intensivstation, Spezialambulanzen, Station',
    catering: 'Kostenloser Zugang zur Krankenhausmensa',
    accommodation: 'Nicht vorhanden',
    application: {
      requiredDocuments: [
        'Lebenslauf',
        'Immatrikulationsbescheinigung',
        'Impfstatus',
      ],
      minDuration: '14 Tage',
      maxDuration: '28 Tage',
    },
  },
]

interface IContactPerson {
  name: string
  email: string
  phone?: string
}

interface IApplication {
  requiredDocuments: string[]
  minDuration: string
  maxDuration: string
}

export interface IInternship {
  id: number
  shortTitle: string
  department: string
  location: string
  locationUrl: string
  imageSrc: string
  description: string
  tasks: string[]
  contactPerson: IContactPerson
  workingHours: string
  facilities: string
  catering: string
  accommodation: string
  application: IApplication
}
