import React from 'react'
import ColorGradientText from 'components/layout/ColorGradientText/ColorGradientText'
import StudentQuickSearchBlock from 'components/layout/Student/QuickSearchBlock/StudentQuickSearchBlock'
import StudentContent from 'components/layout/Student/Content/StudentContent'
import FamulenzNewInternships from 'components/layout/Student/InternshipRecommendations/NewInternships/FamulenzNewInternships'
import FamulenzLastChanceInternships from 'components/layout/Student/InternshipRecommendations/LastChanceInternships/LastChanceInternships'
import FamulenzHr from 'components/layout/Hr/FamulenzHr'

export default function Index(): React.JSX.Element {
  return (
    <>
      <StudentQuickSearchBlock />

      <StudentContent
        id="recommendations-section"
        title={
          <>
            Lass dich <ColorGradientText>inspirieren</ColorGradientText>
          </>
        }
        subtitle="Keine Idee? Kein Problem! Lass dich von unseren frisch eingetroffenen Famulaturen inspirieren oder nutze die Gelegenheit, um dir in letzter Minute noch eine spannende Stelle zu sichern!"
      >
        <>
          <FamulenzNewInternships />

          <FamulenzHr />

          <FamulenzLastChanceInternships />
        </>
      </StudentContent>
    </>
  )
}
