import React, { useEffect, useState } from 'react'
import { type IFamulenzCardWithImageProps } from 'components/layout/CardWithImage/FamulenzCardWithImage'
import { type IInternship } from 'mock/internships'
import FamulenzInternshipRecommendations from '../InternshipRecommendations/FamulenzInternshipRecommendations'

interface IProps {
  internships: IInternship[]
}

export default function FamulenzInternshipList(
  props: IProps,
): React.JSX.Element {
  /* State */
  const [recommendations, setRecommendations] = useState<
    IFamulenzCardWithImageProps[]
  >([])

  /* Hooks */
  useEffect(() => {
    const _recommendations: IFamulenzCardWithImageProps[] = []
    props.internships.forEach(internship => {
      _recommendations.push({
        title: internship.shortTitle,
        text: internship.description,
        to: `/internship/${internship.id}`,
        buttonText: 'Mehr erfahren',
        image: {
          src: internship.imageSrc,
          alt: internship.department,
        },
      })
    })
    setRecommendations(_recommendations)
  }, [props.internships])

  return (
    <>
      {/* TODO: implement some proper design and pagination */}
      <FamulenzInternshipRecommendations
        recommendations={recommendations}
        mdCols={3}
        lgCols={3}
        noMarginBottom
        cardSize="small"
      />
    </>
  )
}
