import FamulenzButton from 'components/control/Button/FamulenzButton'
import FamulenzContinueScrollButton from 'components/control/ContinueScrollButton/FamulenzContinueScrollButton'
import FamulenzLink from 'components/control/Link/FamulenzLink'
import React from 'react'
import StudentContent from '../Content/StudentContent'
import ColorGradientText from 'components/layout/ColorGradientText/ColorGradientText'
import FamulenzSelect from 'components/control/Select/FamulenzSelect'
import styles from './StudentQuickSearchBlock.module.scss'
import { scrollToElement } from 'components/utils/scrollToElement'
import FamulenzDateRangePicker from 'components/control/DateRangePicker/FamulenzDateRangePicker'
import { useNavigate } from 'react-router-dom'

export default function StudentQuickSearchBlock(): React.JSX.Element {
  /* Hooks */
  const navigate = useNavigate()

  /* Handle the scroll when clicking on the button */
  const handleScroll = (): void => {
    scrollToElement('recommendations-section')
  }

  return (
    <StudentContent
      id="quick-search-section"
      title={
        <>
          Finde <ColorGradientText>deine</ColorGradientText> Famulatur!
        </>
      }
      subtitle="Deine Suche hat ein Ende! Sag uns einfach, was dich interessiert und wann du Zeit dafür findest - wir kümmern uns um den Rest. Schnell, einfach und zuverlässig!"
      noMarginTop
      innerChildren={
        <>
          <div className={styles.controls}>
            <div className={styles.controlGrid}>
              <div
                data-aos="fade-right"
                data-aos-delay="400"
                data-aos-anchor="#quick-search-section"
                className={styles.dateRangePickerContainer}
              >
                <FamulenzDateRangePicker
                  onChange={(e: any) => {
                    console.log('on change', e)
                  }}
                />
              </div>

              <div
                data-aos="fade-left"
                data-aos-delay="400"
                data-aos-anchor="#quick-search-section"
                className={styles.selectContainer}
              >
                <FamulenzSelect
                  id="study-field-selection"
                  placeholder="Fachrichtung?"
                  options={[
                    { value: '1', label: 'Allgemeinmedizin' },
                    { value: '2', label: 'Allgemeine Chirurgie' },
                    { value: '3', label: 'Anästhesiologie' },
                    { value: '4', label: 'Angiologie' },
                    { value: '5', label: 'Augenheilkunde' },
                    { value: '6', label: 'Endokrinologie und Diabetologie' },
                    { value: '7', label: 'Frauenheilkunde und Geburtshilfe' },
                    { value: '8', label: 'Gefäßchirurgie' },
                    { value: '9', label: 'Gastroenterologie' },
                    { value: '10', label: 'Hals-Nasen-Ohrenheilkunde' },
                    { value: '11', label: 'Haut- und Geschlechtskrankheiten' },
                    { value: '12', label: 'Hämatologie und Onkologie' },
                    { value: '13', label: 'Herzchirurgie' },
                    { value: '14', label: 'Humangenetik' },
                    { value: '15', label: 'Hygiene und Umweltmedizin' },
                    { value: '16', label: 'Innere Medizin' },
                    { value: '17', label: 'Kardiologie' },
                    { value: '18', label: 'Kinder- und Jugendmedizin' },
                    {
                      value: '19',
                      label:
                        'Kinder- und Jugendpsychiatrie und -psychotherapie',
                    },
                    { value: '20', label: 'Kinderchirurgie' },
                    {
                      value: '21',
                      label:
                        'Mikrobiologie, Virologie und Infektionsepidemiologie',
                    },
                    { value: '22', label: 'Mund-Kiefer-Gesichtschirurgie' },
                    { value: '23', label: 'Neurochirurgie' },
                    { value: '24', label: 'Neurologie' },
                    { value: '25', label: 'Nuklearmedizin' },
                    { value: '26', label: 'Orthopädie und Unfallchirurgie' },
                    { value: '27', label: 'Pathologie' },
                    { value: '28', label: 'Pharmakologie' },
                    {
                      value: '29',
                      label: 'Physikalische und Rehabilitative Medizin',
                    },
                    { value: '30', label: 'Physiologie' },
                    {
                      value: '31',
                      label: 'Plastische und Ästhetische Chirurgie',
                    },
                    { value: '32', label: 'Pneumologie' },
                    { value: '33', label: 'Psychiatrie und Psychotherapie' },
                    { value: '34', label: 'Radiologie' },
                    { value: '35', label: 'Rechtsmedizin' },
                    { value: '36', label: 'Rheumatologie' },
                    { value: '37', label: 'Strahlentherapie' },
                    { value: '38', label: 'Thoraxchirurgie' },
                    { value: '39', label: 'Transfusionsmedizin' },
                    { value: '40', label: 'Urologie' },
                    { value: '41', label: 'Viszeralchirurgie' },
                  ]}
                  clearable
                  searchable
                />
              </div>
            </div>

            <div
              data-aos="fade-up"
              data-aos-delay="600"
              data-aos-anchor="#quick-search-section"
            >
              <FamulenzButton
                className="mt-4"
                onClick={() => {
                  // TODO: also add props for search filters
                  navigate('/internships')
                }}
              >
                Famulatur finden
              </FamulenzButton>
            </div>
          </div>

          <p
            className={styles.subtext}
            data-aos="fade-up"
            data-aos-delay="800"
            data-aos-anchor="#quick-search-section"
          >
            Noch keine Vorstellung?{' '}
            <FamulenzLink onClick={handleScroll}>Klicke hier</FamulenzLink>, um
            unser Angebot zu durchstöbern!
          </p>

          <FamulenzContinueScrollButton onClick={handleScroll} />
        </>
      }
      fullHeight
    />
  )
}
