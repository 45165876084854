import FamulenzButton from 'components/control/Button/FamulenzButton'
import FamulenzLink from 'components/control/Link/FamulenzLink'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './FamulenzCardWithImage.module.scss'
import classNames from 'classnames'

export type IFamulenzCardWithImageSize = 'small' | 'large'

export interface IFamulenzCardWithImageProps {
  title: React.JSX.Element | string
  text: React.JSX.Element | string
  image: {
    src: string
    alt: string
  }
  buttonText: string
  to: string
  dataAosDelay?: number
  dataAosAnchor?: string
  size?: IFamulenzCardWithImageSize
}

export default function FamulenzCardWithImage(
  props: IFamulenzCardWithImageProps,
): React.JSX.Element {
  /* Hooks */
  const navigate = useNavigate()

  const size = props.size ?? 'large'

  return (
    <div
      className={styles.container}
      data-aos="fade-up"
      data-aos-delay={props.dataAosDelay ?? '0'}
      data-aos-anchor={props.dataAosAnchor}
    >
      <FamulenzLink to={props.to}>
        <img
          className={classNames(styles.image, {
            'h-28': size === 'small',
            'h-64': size === 'large',
          })}
          src={props.image.src}
          alt={props.image.alt}
        />
      </FamulenzLink>

      <div className={styles.content}>
        <h5
          className={classNames(styles.title, {
            'text-lg': size === 'small',
            'text-2xl': size === 'large',
          })}
        >
          {props.title}
        </h5>

        <p
          className={classNames(styles.text, {
            'text-sm': size === 'small',
            'text-normal': size === 'large',
          })}
        >
          {props.text}
        </p>

        <FamulenzButton
          className={styles.button}
          onClick={() => {
            navigate(props.to)
          }}
          size="sm"
        >
          {props.buttonText}
          <svg
            className={styles.svg}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </FamulenzButton>
      </div>
    </div>
  )
}
