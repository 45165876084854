import React, { useCallback, useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  loginUserAndLoadPermissions,
  selectIsUserLoggedIn,
  selectUser,
  setUserToken,
} from '../../app/features/User/UserSlice'
import { UserType, type IUser } from '../../app/models/User/User'
import { getCookie } from '../../app/utils/cookies'
import FamulenzLoader from '../../components/control/Loader/FamulenzLoader'
import { usersOwnEndpointThunk } from '../../app/api/endpoints/users/own'
import type { AxiosError } from 'axios'
import { AuthLevel } from 'routes'
import userTransformer from 'app/models/User/User.transformer'

const ProtectedRoute = ({ auth }: { auth: AuthLevel }): React.JSX.Element => {
  /* Hooks */
  const dispatch = useAppDispatch()

  /* Selectors */
  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn)
  const user = userTransformer(useAppSelector(selectUser))

  /* States */
  const [doneLoading, setDoneLoading] = React.useState(false)

  /* Check if the user is already logged in */
  useEffect(() => {
    // If user is already logged in in state, nothing has to be done
    if (isUserLoggedIn) {
      setDoneLoading(true)
      return
    }

    // If the user is not logged in in state but has a token in local storage, dispatch the login action
    const token: string | null = localStorage.getItem('token') ?? null
    const userString = localStorage.getItem('user') ?? null
    if (token !== null && userString !== null) {
      const user: IUser = JSON.parse(userString)
      dispatch(loginUserAndLoadPermissions({ user, token }))

      setDoneLoading(true)
      return
    }

    // Check if there is a token stored as cookie
    const cookieToken: string | undefined = getCookie({ name: 'token' })
    if (cookieToken !== undefined) {
      // Set the token for the user
      dispatch(setUserToken({ token: cookieToken }))

      // Since there is only the token stored as cookie, load the user from the API
      dispatch(usersOwnEndpointThunk({}))
        .unwrap()
        .then((user: IUser) => {
          if (user === undefined) throw new Error()

          dispatch(loginUserAndLoadPermissions({ user, token: cookieToken }))

          setDoneLoading(true)
        })
        .catch((e: AxiosError) => {})

      return
    }

    // If the user is not logged in, simply complete the loading process
    setDoneLoading(true)

    return () => {
      setDoneLoading(false)
    }
  }, [])

  /* Check if the user is authenticated for the specific page */
  const isUserAuthenticatedForRoute = useCallback((): boolean => {
    if (user === null && auth === AuthLevel.PUBLIC) {
      // Public routes are also accessible if user is not logged in
      return true
    }

    // If the user is logged in, check different auth levels
    if (user !== null) {
      switch (user.type) {
        case UserType.STUDENT:
          return auth <= AuthLevel.STUDENT
        case UserType.ORGANIZATION:
          return auth <= AuthLevel.ORGANIZATION
        case UserType.FAMULENZ_STAFF:
          return auth <= AuthLevel.FAMULENZ_STAFF
        default:
          return false
      }
    }

    return false
  }, [auth, user])

  if (!doneLoading) {
    // If not done checking for auth, return nothing
    return (
      <div className="w-full h-full flex items-center justify-center">
        <FamulenzLoader loading={true} />
      </div>
    )
  } else {
    // Redirect to login, the the route is not public and user is not logged in
    return isUserAuthenticatedForRoute() ? <Outlet /> : <Navigate to="/login" />
  }
}

export default ProtectedRoute
