import React, { useCallback, useId, useEffect } from 'react'
import styles from './StudentContent.module.scss'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'

interface IProps {
  id?: string
  title?: React.JSX.Element | string
  subtitle?: React.JSX.Element | string
  innerChildren?: React.JSX.Element | string
  children?: React.JSX.Element | string
  fullHeight?: boolean
  noMarginTop?: boolean
}

export default function StudentContent(props: IProps): React.JSX.Element {
  /* Hooks */
  const id = useId()
  const { pathname } = useLocation()

  /* This is needed to fix the bug that the page is sometimes scrolled to bottom when navigating between pages */
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  /* Check if the content is full height */
  const isFullHeight = useCallback(() => {
    return props.fullHeight === true
  }, [props.fullHeight])

  return (
    <div
      id={props.id ?? id}
      className={classNames(styles.container, {
        [styles.fullHeight]: isFullHeight(),
        [styles.notFullHeight]: !isFullHeight(),
      })}
    >
      <div
        className={classNames(styles.inner, {
          'mt-0': props.noMarginTop === undefined,
          'mt-24 lg:mt-0': props.noMarginTop !== true,
        })}
      >
        {props.title !== undefined && (
          <h2 className={classNames(styles.title, 'h2')} data-aos="fade-up">
            {props.title}
          </h2>
        )}

        {props.subtitle !== undefined && (
          <p
            className={styles.subtitle}
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {props.subtitle}
          </p>
        )}

        {props.innerChildren !== undefined && props.innerChildren}
      </div>

      {props.children !== undefined && props.children}
    </div>
  )
}
