import React from 'react'
import classNames from 'classnames'
import styles from './FamulenzAlert.module.scss'

interface IProps {
  title?: string
  children: React.ReactNode | string
  type: 'success' | 'warning' | 'error' | 'info'
}

const FamulenzAlert: React.FC<IProps> = ({ title, children, type }) => {
  const alertClasses = classNames('p-4 rounded-lg border', {
    'bg-green-100 border-green-400 text-green-400': type === 'success',
    'bg-yellow-100 border-yellow-500 text-yellow-700': type === 'warning',
    'bg-red-100 border-red-500 text-red-500': type === 'error',
  })

  return (
    <div
      className={classNames(styles.alert, alertClasses)}
      role="alert"
      data-aos="fade-up"
    >
      {title !== undefined && <p className={styles.title}>{title}</p>}
      <p>{children}</p>
    </div>
  )
}

export default FamulenzAlert
