import React, { useEffect, useState } from 'react'
import styles from './Login.module.scss'
import FamulenzInput from '../../components/control/Input/FamulenzInput'
import FamulenzCheckbox from '../../components/control/Checkbox/FamulenzCheckbox'
import FamulenzButton from '../../components/control/Button/FamulenzButton'
import FamulenzLink from '../../components/control/Link/FamulenzLink'
import { useNavigate } from 'react-router-dom'
import {
  loginUserAndLoadPermissions,
  selectIsUserLoggedIn,
  selectUser,
} from '../../app/features/User/UserSlice'
import type { ILoginResponse } from '../../app/api/endpoints/login/loginInterface'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  loginEndpointSelector,
  loginEndpointThunk,
} from '../../app/api/endpoints/login'
import { LoadingState } from '../../app/api/models/LoadingState'
import {
  DEFAULT_ERROR_TEXT,
  FIELD_REQUIRED_ERROR_TEXT,
} from '../../app/constants/texts'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { emailPattern } from '../../app/constants/validationPatterns'
import {
  ApiErrorStatus,
  type IApiError,
  mapAxiosErrorToApiError,
} from '../../app/api/models/Errors'
import { type AxiosError } from 'axios'
import { UserType } from 'app/models/User/User'
import userTransformer from 'app/models/User/User.transformer'

interface ILoginForm {
  email: string
  password: string
}

export default function Login(): React.JSX.Element {
  /* States */
  const [staySignedIn, setStaySignedIn] = useState<boolean>(false)
  const [errorString, setErrorString] = useState<string | null>(null)

  /* Hooks */
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ILoginForm>()

  /* Selectors */
  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn)
  const { loading } = useAppSelector(loginEndpointSelector)
  const user = userTransformer(useAppSelector(selectUser))

  /* Redirect if the user is logged in */
  useEffect(() => {
    if (isUserLoggedIn && user !== null) {
      if (user.type === UserType.STUDENT) {
        navigate('/')
      } else {
        navigate('/organization')
      }
    }
  }, [isUserLoggedIn])

  /* Handle the login form submission */
  const onSubmit: SubmitHandler<ILoginForm> = data => {
    // Dispatch the login action
    dispatch(
      loginEndpointThunk({ username: data.email, password: data.password }),
    )
      .unwrap()
      .then((response: ILoginResponse) => {
        dispatch(loginUserAndLoadPermissions({ ...response, staySignedIn }))
      })
      .catch((error: AxiosError) => {
        const apiError: IApiError = mapAxiosErrorToApiError(error)
        if (apiError.status === ApiErrorStatus.BadRequest) {
          setErrorString(
            'Die Kombination aus Email und Passwort ist nicht korrekt.',
          )
        } else if (apiError.status === ApiErrorStatus.Conflict) {
          // This means that the account has not been verified yet
          navigate('/register/needs-verification', {
            state: { email: watch('email') },
          })
        } else {
          setErrorString(DEFAULT_ERROR_TEXT)
        }
      })
  }

  return (
    <>
      <h1 className={styles.loginHeader}>Anmeldung</h1>

      <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)}>
        <div>
          <FamulenzInput
            label="Email"
            id="email"
            placeholder="student@famulenz.at"
            autoComplete="email"
            register={register('email', {
              required: FIELD_REQUIRED_ERROR_TEXT,
              pattern: emailPattern,
            })}
            error={errors?.email?.message}
            required
          />
        </div>

        <div>
          <FamulenzInput
            label="Passwort"
            id="password"
            placeholder="••••••••"
            type="password"
            autoComplete="current-password"
            register={register('password', {
              required: FIELD_REQUIRED_ERROR_TEXT,
            })}
            error={errors?.password?.message}
            required
          />
        </div>

        <div className={styles.loginFormLowerSection}>
          <div className={styles.rememberMe}>
            <FamulenzCheckbox
              id="remember"
              label="Eingeloggt bleiben"
              onChange={e => {
                setStaySignedIn(e.target.checked)
              }}
            />
          </div>
          <FamulenzLink to="/password-reset">Passwort vergessen?</FamulenzLink>
        </div>

        {errorString !== null && (
          <p className={styles.errorText}>{errorString}</p>
        )}

        <FamulenzButton
          type="submit"
          loading={loading === LoadingState.Pending}
        >
          Anmelden
        </FamulenzButton>

        <p className={styles.registerText}>
          Noch kein Account?{' '}
          <FamulenzLink to="/register">Jetzt Registrieren</FamulenzLink>
        </p>
      </form>
    </>
  )
}
