import classNames from 'classnames'
import React, { useCallback, useState } from 'react'
import styles from './FamulenzInput.module.scss'
import { type UseFormRegisterReturn } from 'react-hook-form'
import { type IconType } from 'react-icons'
import { BsXCircle } from 'react-icons/bs'
import FamulenzInputBase, {
  type IFamulenzInputBaseProps,
} from '../InputBase/FamulenzInputBase'

export interface IFamulenzInputProps
  extends Omit<IFamulenzInputBaseProps, 'children'> {
  // The placeholder for the input field
  placeholder: string
  // The default value of the input field
  defaultValue?: string
  // Event handler for listening to the changed input value
  // (Note: This could also be done via the watch function of the react-hook-form library.)
  onValueChange?: (value: string) => void
  // The type of the input field
  type?: string
  // The autoComplete attribute of the input field
  autoComplete?: string
  // Return from the register function of react-hook-form used for validation
  register?: UseFormRegisterReturn
  // The actual error message
  error?: string
  // The icon on the left side
  icon?: IconType
  // Flag to make the input clearable
  clearable?: boolean
  // onClick event handler
  onClick?: () => void
  // Flag to make input readonly
  readOnly?: boolean
  // Class name for the input field
  className?: string
  // Custom icon on the right side
  customIcon?: React.ReactNode
  // Event handler for click on custom icon
  onCustomIconClick?: () => void
}

export default function FamulenzInput(
  props: IFamulenzInputProps,
): React.JSX.Element {
  /* States */
  const [value, setValue] = useState<string | number>(props.defaultValue ?? '')

  /* Callbacks */
  const hasError = useCallback(() => {
    return props.error !== undefined && props.error !== ''
  }, [props.error])

  return (
    <FamulenzInputBase {...props}>
      <div className={styles.inputContainer}>
        {props.icon !== undefined && (
          <div className={styles.leftIconContainer}>
            <props.icon className={styles.icon} size={16} />
          </div>
        )}

        <input
          {...props.register}
          ref={props.register?.ref}
          id={props.id}
          className={classNames('form-input', styles.input, props.className, {
            [styles.inputError]: hasError(),
            [styles.inputSuccess]: !hasError(),
            [styles.inputWithIcon]: props.icon !== undefined,
          })}
          placeholder={props.placeholder}
          required={props.required}
          onChange={e => {
            setValue(e.target.value)
            props.register?.onChange(e)
            if (props.onValueChange !== undefined) {
              props.onValueChange(e.target.value)
            }
          }}
          type={props.type ?? 'text'}
          autoComplete={props.autoComplete}
          value={props.register === undefined ? value : undefined}
          onClick={props.onClick}
          readOnly={props.readOnly}
        />

        {/* Does not work with react hook form  */}
        {props.clearable === true &&
          props.register === undefined &&
          value !== '' && (
            <>
              <button
                type="button"
                className={styles.rightIconContainer}
                onClick={() => {
                  setValue('')
                  if (props.onValueChange !== undefined) {
                    props.onValueChange('')
                  }
                }}
              >
                <BsXCircle size={20} className={styles.icon} />
              </button>
            </>
          )}

        {/* Custom icon */}
        {props.customIcon !== undefined && (
          <div
            className={styles.rightCustomIconContainer}
            onClick={props.onCustomIconClick}
          >
            <span className={styles.seperator} />
            <div className={styles.iconContainer}>{props.customIcon}</div>
          </div>
        )}
      </div>
    </FamulenzInputBase>
  )
}
