import {
  OrganizationalUnitType,
  type IOrganizationalUnit,
} from './OrganizationalUnit'

export interface IOrganizationalUnitTransformer extends IOrganizationalUnit {
  nameWithParent: string
  parentsNames: string
  type: string
}

export const getTypeNameFromOrganizationalUnitType = (
  type: OrganizationalUnitType,
): string => {
  switch (type) {
    case OrganizationalUnitType.STATION:
      return 'Abteilung'
    case OrganizationalUnitType.HOUSE:
      return 'Haus'
    case OrganizationalUnitType.ORGANIZATION:
      return 'Dachverband'
  }
}
export default function organizationalUnitTransformer(
  organizationalUnit: IOrganizationalUnit | null,
): IOrganizationalUnitTransformer | null {
  if (organizationalUnit === null) {
    return null
  }

  const getNameWithParent = (): string => {
    let name = organizationalUnit.name

    if (
      organizationalUnit?.parents !== undefined &&
      organizationalUnit?.parents.length > 0
    ) {
      // Take the first parent
      name += ` - ${organizationalUnit?.parents[0].name}`
    }

    return name
  }

  /* Returns the names of all the parents */
  const getParentsNames = (): string => {
    if (
      organizationalUnit?.parents !== undefined &&
      organizationalUnit?.parents.length > 0
    ) {
      return organizationalUnit?.parents.map(parent => parent.name).join(' - ')
    }

    return ''
  }

  return {
    ...organizationalUnit,
    nameWithParent: getNameWithParent(),
    parentsNames: getParentsNames(),
    type: getTypeNameFromOrganizationalUnitType(organizationalUnit.ou_type),
  }
}
