import React from 'react'
import FamulenzInternshipRecommendations from '../FamulenzInternshipRecommendations'
import ColorGradientText from 'components/layout/ColorGradientText/ColorGradientText'
import FamulenzLink from 'components/control/Link/FamulenzLink'
import { type IFamulenzCardWithImageProps } from 'components/layout/CardWithImage/FamulenzCardWithImage'
import { mockInternships } from 'mock/internships'
import { useNavigate } from 'react-router-dom'

interface IProps {
  dataAosDelay?: number
}

export default function FamulenzNewInternships(
  props: IProps,
): React.JSX.Element {
  /* Hooks */
  const navigate = useNavigate()

  // TODO: load internships from API
  const recommendations: IFamulenzCardWithImageProps[] = []
  mockInternships.forEach(internship => {
    if (internship.id <= 3) {
      recommendations.push({
        title: internship.shortTitle,
        text: internship.description,
        to: `/internship/${internship.id}`,
        buttonText: 'Mehr erfahren',
        image: {
          src: internship.imageSrc,
          alt: internship.department,
        },
      })
    }
  })

  return (
    <>
      <FamulenzInternshipRecommendations
        title={
          <>
            <ColorGradientText>Neu</ColorGradientText> mit dabei
          </>
        }
        subtext={
          <>
            Klingt spannend?{' '}
            <FamulenzLink
              onClick={() => {
                // TODO: also add props for search filters
                navigate('/internships')
              }}
            >
              Klicke hier
            </FamulenzLink>{' '}
            um weitere Famulaturen zu finden, die kürzlich hinzugefügt wurden.
          </>
        }
        recommendations={recommendations}
        dataAosDelay={props.dataAosDelay ?? 0}
        mdCols={1}
        lgCols={3}
      />
    </>
  )
}
