import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import { type IUser } from '../../models/User/User'
import { deleteCookie, setCookie } from '../../utils/cookies'
import { type IPermission } from 'app/models/Permission/Permission'

/* Interface for the state slice */
export interface UserState {
  user: IUser | null
  token: string | null
  permissions: IPermission[] | null
}

/* Initial state */
const initialState: UserState = {
  user: null,
  token: null,
  permissions: null,
}

// Thunk action to handle login and load permissions
export const loginUserAndLoadPermissions = createAsyncThunk(
  'user/loginUserAndLoadPermissions',
  async (
    {
      user,
      token,
      staySignedIn,
    }: {
      user: IUser
      token: string
      staySignedIn?: boolean
    },
    { dispatch },
  ) => {
    // Dispatch loginUserAction to set user and token in the state
    dispatch(loginUserAction({ user, token, staySignedIn }))

    // Dispatch permissionsEndpointThunk to load permissions
    // TODO: This seems to be the wrong endpoint, this returns all permissions. Need to have a specific endpoint for getting the permissions of the user. Then need to dispatch the action to set the permissions in the state
    // await dispatch(permissionsEndpointThunk({}))
  },
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginUserAction: (
      state,
      action: PayloadAction<{
        user: IUser
        token: string
        staySignedIn?: boolean
      }>,
    ) => {
      state.user = action.payload.user
      state.token = action.payload.token

      // Store to local store to keep user logged in
      localStorage.setItem('user', JSON.stringify(action.payload.user))
      localStorage.setItem('token', action.payload.token)

      // If the user wants to stay signed in, also store the token as cookie
      if (action.payload.staySignedIn === true) {
        setCookie({
          name: 'token',
          val: action.payload.token,
          expire: new Date(new Date().setMonth(new Date().getMonth() + 1)),
        })
      }
    },
    logoutUserAction: (state, action: PayloadAction<void>) => {
      // Delete the cookie
      deleteCookie({ name: 'token' })

      // Remove from local store
      localStorage.removeItem('user')
      localStorage.removeItem('token')

      state.user = null
      state.token = null
      state.permissions = null
    },
    setUserToken: (state, action: PayloadAction<{ token: string }>) => {
      state.token = action.payload.token
    },
  },
})

/**
 * Export actions
 */
export const { loginUserAction, logoutUserAction, setUserToken } =
  userSlice.actions

/**
 * Export selectors
 */
export const selectIsUserLoggedIn = (state: RootState): boolean => {
  return state.user.token !== null && state.user.user !== null
}

export const selectUser = (state: RootState): IUser | null => {
  return state.user.user
}

export const selectUserToken = (state: RootState): string | null => {
  return state.user.token
}

export const selectUserPermissions = (
  state: RootState,
): IPermission[] | null => {
  return state.user.permissions
}

/**
 * Export default reducer
 */
export default userSlice.reducer
