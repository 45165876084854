import flattenDeep from 'lodash/flattenDeep'
import React, { type ReactElement } from 'react'
import { Route, Routes as ReactRoutes, Navigate } from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'
import { type IRoute, type ILayoutRoute, AuthLevel } from '.'
import { type Flat } from 'lodash'

export const generateFlattenRoutes = (
  routes: IRoute[] | undefined,
): Array<Flat<any>> => {
  if (routes === undefined || routes === null) return []
  return flattenDeep(
    routes.map(({ routes: subRoutes, ...rest }) => [
      rest,
      generateFlattenRoutes(subRoutes),
    ]),
  )
}

type IRenderRoutes = () => ReactElement

export const renderRoutes = (mainRoutes: ILayoutRoute[]): IRenderRoutes => {
  const Routes = (): ReactElement => {
    const layouts = mainRoutes.map(
      ({ layout: Layout, routes }: ILayoutRoute, index) => {
        const subRoutes = generateFlattenRoutes(routes)

        return (
          <Route key={index} element={<Layout />}>
            {subRoutes.map(
              ({
                component: Component,
                path,
                name,
                auth,
                redirect,
              }: {
                component: React.ComponentType
                path: string
                name: string
                auth: AuthLevel | undefined
                redirect: string | undefined
              }) => {
                return (
                  <Route
                    key={name}
                    element={<ProtectedRoute auth={auth ?? AuthLevel.PUBLIC} />}
                  >
                    {redirect !== undefined ? (
                      <Route path={path} element={<Navigate to={redirect} />} />
                    ) : (
                      Component !== undefined &&
                      path !== '' && (
                        <Route element={<Component />} path={path} />
                      )
                    )}
                  </Route>
                )
              },
            )}
          </Route>
        )
      },
    )
    return <ReactRoutes>{layouts}</ReactRoutes>
  }
  return Routes
}
