import React, { useCallback } from 'react'
import styles from './AdministrationHeader.module.scss'
import FamulenzLink from '../../control/Link/FamulenzLink'
import FamulenzLogo from '../FamulenzLogo/FamulenzLogo'
import Avatar from '../Avatar/Avatar'
import { useLocation } from 'react-router-dom'
import { getTitleFromPath } from 'routes/helper'

export default function AdministrationHeader(): React.JSX.Element {
  /* Hooks */
  const location = useLocation()

  /* Get the title depending on the URL */
  const getTitle = useCallback((): string => {
    return getTitleFromPath(location.pathname)
  }, [location.pathname])

  return (
    <nav className={styles.nav}>
      <div className={styles.inner}>
        <div className={styles.left}>
          <FamulenzLink to="/">
            <FamulenzLogo size="xs" />
          </FamulenzLink>
          {getTitle() !== '' && (
            <span className="text-xl ml-4">{getTitle()}</span>
          )}
        </div>
        <div className={styles.right}>
          <Avatar
            middleSection={[
              { to: '/', text: 'Studentenansicht' },
              // TODO: enable and implement other pages
              // { to: '#', text: 'Benutzerprofil' },
              // { to: '#', text: 'Einstellungen' },
              // { to: '#', text: 'Feedback' },
            ]}
            endSection={[{ to: '/logout', text: 'Abmelden' }]}
          />
        </div>
      </div>
    </nav>
  )
}
