// TODO: remove
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DataGrid,
  type GridRowId,
  type GridColDef,
  type GridRowsProp,
  GridActionsCellItem,
  type GridPaginationModel,
  type GridSortModel,
} from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import {
  BsEye,
  BsFillCheckCircleFill,
  BsFillXCircleFill,
  BsPencilSquare,
  BsSearch,
} from 'react-icons/bs'
import styles from './InternshipsTable.module.scss'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  organizationInternshipOffersOwnEndpointSelector,
  organizationInternshipOffersOwnEndpointThunk,
} from 'app/api/endpoints/organization/internship-offers/own'
import { type IOrganizationInternshipOffersOwnRequest } from 'app/api/endpoints/organization/internship-offers/own/organizationInternshipOffersOwnInterface'
import LoaderWrapper from 'components/control/LoaderWrapper/LoaderWrapper'
import { LoadingState } from 'app/api/models/LoadingState'
import internshipOfferTransformer, {
  IInternshipOfferTransformer,
} from 'app/models/InternshipOffer/InternshipOffer.transformer'
import { SortOrder } from 'app/models/Pagination/Pagination'
import FamulenzInput from 'components/control/Input/FamulenzInput'
import classNames from 'classnames'
import { DEFAULT_ERROR_TEXT } from 'app/constants/texts'
import FamulenzButton from 'components/control/Button/FamulenzButton'
import { toast } from 'react-toastify'
import {
  organizationInternshipsAllEndpointSelector,
  organizationInternshipsAllEndpointThunk,
} from 'app/api/endpoints/organization/internships/all'
import { type IOrganizationInternshipsAllRequest } from 'app/api/endpoints/organization/internships/all/organizationInternshipsAllInterface'
import internshipTransformer from 'app/models/Internship/Internship.transformer'

// TODO: make own component out of this and combine with InternshipsTable
export default function InternshipsTable(): React.JSX.Element {
  /* Hooks */
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { payload, loading, error } = useAppSelector(
    organizationInternshipsAllEndpointSelector,
  )

  /* States */
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  })
  const [sortModel, setSortModel] = useState<GridSortModel>([])
  const [numberResults, setNumberResults] = useState<number>(0)
  const [searchValue, setSearchValue] = useState<string>('')
  // Need a debounced model here, since otherwise a new request would be sent on every key stroke
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('')

  /* Debouncing the search value and introducing artificial delay */
  useEffect(() => {
    const debounceTimeout = window.setTimeout(() => {
      setDebouncedSearchValue(searchValue)
    }, 500)

    // Important to clear the timeout on dismount or before another effect
    return () => {
      clearTimeout(debounceTimeout)
    }
  }, [searchValue, setDebouncedSearchValue])

  /* Load API data */
  useEffect(() => {
    const data: IOrganizationInternshipsAllRequest = {
      page_size: paginationModel.pageSize,
      // Pagination Model is zero based and API pagination is one based
      page: paginationModel.page + 1,
      search: debouncedSearchValue,
    }

    dispatch(organizationInternshipsAllEndpointThunk(data))
  }, [paginationModel, sortModel, debouncedSearchValue])

  /* Update the number of results when the loading of a new API request is finished */
  useEffect(() => {
    if (loading === LoadingState.Success) {
      setNumberResults(payload?.count ?? 0)
    }
  }, [loading, payload])

  /* Callbacks for CRUD operations */
  const handleEditClick = (id: GridRowId): void => {
    // TODO: implement edit click
    toast.warn(
      'Sie haben keine Berechtigung, um diesen Famulaturplatz zu bearbeiten.',
    )
  }
  const handleViewClick = (id: GridRowId): void => {
    // TODO: implement view click
    toast.warn(
      'Sie haben keine Berechtigung, um diesen Famulaturplatz anzusehen.',
    )
  }

  /* The rows for the table */
  const rows = payload?.results.map(internshipTransformer) ?? []

  /* Definition for columns */
  const columns: GridColDef[] = [
    {
      field: 'student_name',
      headerName: 'Student',
      description:
        'Der Student/Die Studentin, der/die die Stelle angenommen hat',
      minWidth: 300,
      sortable: false,
    },
    {
      field: 'type',
      headerName: 'Typ',
      description: 'Der Typ der Stelle',
      sortable: false,
      minWidth: 200,
    },
    {
      field: 'station_name',
      headerName: 'Station',
      description: 'Die Station, auf der die Stelle angenommen wurde',
      sortable: false,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'timespan',
      headerName: 'Zeitraum',
      description: 'Der Zeitraum, in dem die Stelle absolviert wird',
      sortable: false,
      minWidth: 250,
    },
    {
      field: 'actions',
      headerName: 'Aktionen',
      type: 'actions',
      sortable: false,
      getActions: ({ id }: { id: GridRowId }) => {
        return [
          <GridActionsCellItem
            key="view"
            icon={<BsEye size={16} />}
            label="Vorschau"
            onClick={() => {
              handleViewClick(id)
            }}
          />,
          <GridActionsCellItem
            key="edit"
            icon={<BsPencilSquare size={16} />}
            label="Bearbeiten"
            onClick={() => {
              handleEditClick(id)
            }}
          />,
        ]
      },
    },
  ]

  return (
    <div className={styles.container}>
      {/* TODO: move the logic to an own paginated component? */}

      <div className={styles.actions}>
        <FamulenzInput
          id="search"
          placeholder="Nach Stelle suchen..."
          className={styles.search}
          defaultValue={searchValue}
          onValueChange={val => {
            setSearchValue(val)
          }}
          icon={BsSearch}
          clearable
        />

        {/* TODO: handle create button click */}
        <FamulenzButton
          className={styles.createButton}
          onClick={() => {
            toast.warn(
              'Sie haben keine Berechtigung, um eine neue Stelle zu erstellen.',
            )
          }}
        >
          Neue Stelle
        </FamulenzButton>
      </div>

      {/* Show error message in case of error */}
      {error !== null && (
        <>
          <p className={styles.error}>{DEFAULT_ERROR_TEXT}</p>
        </>
      )}

      <DataGrid
        className={classNames('rows-clickable', styles.table)}
        rows={rows}
        columns={columns}
        editMode="cell"
        loading={loading === LoadingState.Pending}
        disableRowSelectionOnClick
        onRowClick={event => {
          handleEditClick(event.id)
        }}
        pageSizeOptions={[5, 10, 25, 50]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        rowCount={numberResults}
        paginationMode="server"
        disableColumnFilter={true}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={setSortModel}
      />
    </div>
  )
}
