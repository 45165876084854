import type {
  IPermissionsRequest,
  IPermissionsResponse,
} from './permissionsInterface'
import createApiEndpoint from 'app/api/utils/createApiEndpoint'
import type { RootState } from 'app/store'
import type { IApiState } from 'app/api/models/ApiState'

/* Create selector */
const permissionsEndpointSelector = (
  state: RootState,
): IApiState<IPermissionsResponse> => state.api.permissions

/* Create reducer and thunk */
const { reducer: permissionsEndpointReducer, thunk: permissionsEndpointThunk } =
  createApiEndpoint<IPermissionsRequest, IPermissionsResponse>({
    endpoint: '/permissions',
    method: 'GET',
    stateName: 'permissions',
  })

/* Export reducer, selector and thunk action */
export { permissionsEndpointSelector, permissionsEndpointThunk }
export default permissionsEndpointReducer
