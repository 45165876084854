import React from 'react'
import FamulenzInternshipRecommendations from '../FamulenzInternshipRecommendations'
import ColorGradientText from 'components/layout/ColorGradientText/ColorGradientText'
import FamulenzLink from 'components/control/Link/FamulenzLink'
import { type IFamulenzCardWithImageProps } from 'components/layout/CardWithImage/FamulenzCardWithImage'
import { mockInternships } from 'mock/internships'
import { useNavigate } from 'react-router-dom'

interface IProps {
  dataAosDelay?: number
}

export default function FamulenzLastChanceInternships(
  props: IProps,
): React.JSX.Element {
  /* Hooks */
  const navigate = useNavigate()

  // TODO: load internships from API
  const recommendations: IFamulenzCardWithImageProps[] = []
  mockInternships.forEach(internship => {
    if (internship.id > 3 && internship.id <= 7) {
      recommendations.push({
        title: internship.shortTitle,
        text: internship.description,
        to: `/internship/${internship.id}`,
        buttonText: 'Mehr erfahren',
        image: {
          src: internship.imageSrc,
          alt: internship.department,
        },
      })
    }
  })

  return (
    <>
      <FamulenzInternshipRecommendations
        title={
          <>
            <ColorGradientText>Letzte</ColorGradientText> Chance
          </>
        }
        subtext={
          <>
            Nutze die Gelegenheit und finde{' '}
            <FamulenzLink
              onClick={() => {
                // TODO: also add props for search filters
                navigate('/internships')
              }}
            >
              hier
            </FamulenzLink>{' '}
            weitere Famulaturen, bei denen nur noch wenige Plätze frei sind.
          </>
        }
        recommendations={recommendations}
        dataAosDelay={props.dataAosDelay ?? 0}
        mdCols={2}
        lgCols={2}
        noMarginBottom
      />
    </>
  )
}
